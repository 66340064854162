import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="selected-rows"
export default class extends Controller {
  static targets = ['rowCheckbox']

  // Reusable for any type of action (delete multiple, archive multiple etc.)
  // Url, title and text are all required, warning is optional
  async actionWithConfirmation({ target }) {
    const { url, title, text, warning } = target.dataset
    const checked = this.rowCheckboxTargets.filter((box) => box.checked)
    const ids = checked.map((box) => +box.value)

    const callback = async () => {
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ ids }),
        })

        if (!res.ok) throw new Error()

        // Reload the page after a successful request (DataTables and paginated tables get messed up when deleting rows dynamically)
        location.reload()
      } catch (error) {
        alert(window.I18n.unknown_error)
      }
    }

    await window.confirmationModal(title, text, callback, warning)
  }
}
