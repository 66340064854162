import { Controller } from '@hotwired/stimulus'
import Player from '@vimeo/player'

// Connects to data-controller="shared--course-video"
export default class extends Controller {
  connect() {
    //Variables for watching and selecting the videos
    const subjectId = +$('#course_video').attr('data-value')
    var clicked_video_id = 0
    var previousSave = null

    //Initialize vimeo player with video id
    var selected_course_video_progress = Math.max(
      parseInt($('#selected_course_video_progress_header').attr('data-seconds')) - 1,
      0
    )
    var selected_video_id = parseInt($('#course_video').data('id'))
    var player = new Player('course_video', {
      id: selected_video_id,
      portrait: false,
      byline: false,
      controls: true,
      start_time: selected_course_video_progress,
    })
    var timeWatched = selected_course_video_progress

    //Handler for when selecting video from the list
    $('.video_button').on('click', function () {
      clicked_video_id = parseInt($(this).data('id'))

      if (selected_video_id != clicked_video_id) {
        $('#course_video').data('id', clicked_video_id)
        let play_time = parseInt($('#list_course_video_progress_header_' + clicked_video_id).attr('data-seconds'))
        $('#selected_course_video_title').text($('#list_course_video_name_' + clicked_video_id).text())
        $('#selected_course_video_duration').text($('#list_course_video_time_' + clicked_video_id).text())
        $('.selected_course_video_progress').val($('#list_course_video_progress_' + clicked_video_id).val())
        $('#selected_course_video_progress_header').text(
          $('#list_course_video_progress_header_' + clicked_video_id).text()
        )
        selected_video_id = clicked_video_id
        loadVideo(player, clicked_video_id, play_time)
        timeWatched = play_time
      }

      //Scrolling back to main video container when video selected from the list
      $('html, body').animate(
        {
          scrollTop: $('.main-content').offset().top,
        },
        600
      )
    })

    // Update video state, data-attributes and header texts when watching a video
    player.on('timeupdate', function (data) {
      // Update data-attributes and visible text
      updateDatafields(data)

      const seconds = data.seconds
      if (seconds - 1 < timeWatched && seconds > timeWatched) {
        timeWatched = seconds
      }

      // Save progress every 30 seconds
      // 'timeupdate' fires 4-5x per second, so keep track of the previous save to prevent multiple saves during the same second
      const watchedSeconds = Math.floor(timeWatched)
      if (watchedSeconds % 30 === 0 && watchedSeconds !== previousSave) {
        previousSave = watchedSeconds
        saveState()
      }
    })

    //Saving users video status when video is paused
    player.on('pause', saveState)

    //Saving users video status when video is finished
    player.on('ended', saveState)

    if (window.location.pathname.includes('/live_course/')) {
      // Disable seeking on the video if the user hasn't fully watched it yet
      player.on('seeked', ({ seconds }) => {
        if (timeWatched < seconds) {
          player.setCurrentTime(timeWatched)
        }
      })

      window.addEventListener('pauseCourseVideo', (e) => {
        player.getPaused().then((paused) => {
          if (!paused) player.pause()
        })
      })
    }

    //Function for loading specific video from vimeo
    async function loadVideo(player, clicked_video_id, play_time) {
      try {
        await player.pause()
        await player.loadVideo(clicked_video_id)
        // Make sure play_time is within an acceptable range, confirming the video duration from the Vimeo API first
        const duration = await player.getDuration()
        const playTime = Math.min(Math.max(play_time, 0), duration)
        if (playTime) {
          await player.setCurrentTime(play_time)
        }
        await player.play()
      } catch (error) {
        switch (error.name) {
          case 'TypeError':
            alert('Type error!')
            break
          case 'PasswordError':
            alert('Wrong password!')
            break
          case 'PrivacyError':
            alert('Private video.')
            break
          case 'RangeError':
            alert('RangeError.')
            break
          default:
            break
        }
      }
    }

    // Save video progress
    async function saveState() {
      try {
        const currentTime = await player.getCurrentTime()
        if (currentTime < 1) return

        const videoId = await player.getVideoId()

        const resultData = {
          video_progress: {
            subject_id: subjectId,
            video_id: videoId,
            watch_time: Math.round(currentTime),
          },
        }

        const csrfToken = document.querySelector('meta[name="csrf-token"]').content
        const headers = { 'X-CSRF-Token': csrfToken, 'Content-Type': 'application/json' }
        const res = await fetch('/save_vimeo_state', { method: 'POST', headers, body: JSON.stringify(resultData) })

        if (!res.ok) {
          const msg = await res.text()
          throw new Error(msg)
        }
      } catch (error) {
        flash('alert', error.message)
      }
    }

    //Function for updating the data fields
    function updateDatafields(data) {
      var percentWatched = data.percent * 100
      var percentageText = Math.round(percentWatched) + '%'
      var id = $('#course_video').data('id')
      $('.selected_course_video_progress').attr('value', percentWatched)
      $('#selected_course_video_progress_header').text(percentageText)
      $('#list_course_video_progress_' + id).attr('value', percentWatched)
      $('#list_course_video_progress_header_' + id).attr('data-seconds', Math.round(data.seconds))
      $('#list_course_video_progress_header_' + id).text(percentageText)

      if (percentWatched >= 100) document.dispatchEvent(new CustomEvent('checkVideoProgress'))
    }
  }
}
